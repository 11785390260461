<template>
	<div class="vld-parent">
	<loading :active="loading" color="#D9F7C5" loader="bars"/>
	</div>
	<div class="p-grid">
		<div class="p-col-12">
			<div class="card">
				<Toast/>
				<!-- <img :src="'assets/demo/images/product/' + product.image" :alt="product.image" class="product-image" v-if="product.image" /> -->
				<h5>Cuentahabiente</h5>
				<div class="p-fluid p-formgrid p-grid">
				<div class="p-field p-col">
					<label for="codigo">Código</label>
					<InputText id="codigo" v-model.trim="ctahabiente.codigo" autofocus :class="{'p-invalid': submitted && !ctahabiente.codigo}" />
				</div>
				<div class="p-field p-col">
					<label class="p-col p-as-center" for="activo">Activo</label>
					<InputSwitch v-model="ctahabiente.activoDisplay"/>
				</div>
				</div>				
				<div class="p-field">
					<label for="id_cta_habiente_tipo">Tipo</label>
					<Dropdown v-model="ctahabiente.id_cta_habiente_tipo" required="true" :options="lkpCtaHabienteTipos" optionLabel="cta_habiente_tipo" optionValue="id_cta_habiente_tipo" placeholder="Seleccionar..." />
				</div>						
				<TabView>
					<TabPanel>
						<template #header>
							<i class="pi pi-globe p-m-1"></i>
							<span>Generales</span>
						</template>
						<div class="p-fluid">
							<div class="p-field">
								<label for="nit">NIT</label>
								<InputText id="NIT" v-model.trim="ctahabiente.nit" required="true" :class="{'p-invalid': submitted && !ctahabiente.nit}" />
								<small class="p-invalid" v-if="submitted && !ctahabiente.nit">NIT es requerido.</small>
							</div>					
							<div class="p-field">
								<label for="nombre_comercial">Nombre Comercial</label>
								<InputText id="nombre_comercial" v-model.trim="ctahabiente.nombre_comercial" required="true" :class="{'p-invalid': submitted && !ctahabiente.nombre_comercial}" />
								<small class="p-invalid" v-if="submitted && !ctahabiente.nombre_comercial">Nombre comercial es requerido.</small>
							</div>
							<div class="p-field">
								<label for="razon_social">Razón Social</label>
								<InputText id="razon_social" v-model="ctahabiente.razon_social" required="true"  :class="{'p-invalid': submitted && !ctahabiente.razon_social}"/>
								<small class="p-invalid" v-if="submitted && !ctahabiente.razon_social">La razon soical es requerida.</small>
							</div>
							<div class="p-field">
								<label for="razon_social">Teléfono</label>
								<InputText id="telefono" v-model.trim="ctahabiente.telefono" required="true" :class="{'p-invalid': submitted && !ctahabiente.telefono}" />
								<small class="p-invalid" v-if="submitted && !ctahabiente.telefono">El telefono es requerido.</small>
							</div>	
							<div class="p-field">
								<label for="razon_social">Email</label>
								<InputText id="email" v-model.trim="ctahabiente.email" required="true" :class="{'p-invalid': submitted && !ctahabiente.email}" />
								<small class="p-invalid" v-if="submitted && !ctahabiente.email">El email es requerido.</small>
							</div>
							<div class="p-field">
								<label for="razon_social">Website</label>
								<InputText id="website" v-model.trim="ctahabiente.website" required="false" />
							</div>																			
							<div class="p-field">
								<label for="id_cta_habiente_categoria">Categoría</label>
								<Dropdown v-model="ctahabiente.id_cta_habiente_categoria" :options="lkpCategorias" optionLabel="categoria" optionValue="id_cta_habiente_categoria" placeholder="Seleccionar..." />
							</div>					
							<!-- <div class="p-field">
								<label for="id_region">Región</label>
								<Dropdown v-model="ctahabiente.id_region" :options="lkpRegiones" optionLabel="region" optionValue="id_region" placeholder="Seleccionar..." />
							</div> -->

							<div class="p-field">
								<label for="id_precio_lista">Lista de Precios</label>
								<Dropdown v-model="ctahabiente.id_precio_lista" :options="lkpPreciosLista" optionLabel="precio_lista" optionValue="id_precio_lista" placeholder="Seleccionar..." />
							</div>		

							<!-- <div class="p-field">
								<label for="id_producto_lista">Lista de Productos</label>
								<Dropdown v-model="ctahabiente.id_producto_lista" :options="lkpProductosLista" optionLabel="lista" optionValue="id_producto_lista" placeholder="Seleccionar..." />
							</div>	 -->

							<div class="p-field">
								<label for="pct_descuento">% Descuento</label>
								<InputNumber id="pct_descuento" v-model="ctahabiente.pct_descuento" prefix="%" />
							</div>	
							<div class="p-formgroup-inline">
								<div class="p-field">
									<label for="limite_credito">Límite de Crédito</label>
									<InputNumber id="limite_credito" v-model="ctahabiente.limite_credito" mode="currency" currency="USD" locale="en-US" :min="0"/>
								</div>		
								<div class="p-field">
									<label for="id_moneda">Moneda</label>
									<Dropdown v-model="ctahabiente.id_moneda" :options="lkpMonedas" optionLabel="moneda" optionValue="id_moneda" placeholder="Seleccionar..." />
								</div>	
							</div>
							<div class="p-field">
								<label for="nit_documento">Facturar al NIT</label>
								<InputText id="nit_documento" v-model.trim="ctahabiente.nit_documento" />
							</div>	
							<div class="p-field">
								<label for="nombre_documento">Emitir Documentos a Nombre de</label>
								<InputText id="nombre_documento" v-model.trim="ctahabiente.nombre_documento" />
							</div>	
							<div class="p-field">
								<label for="direccion_documento">Dirección</label>
								<InputText id="direccion_documento" v-model.trim="ctahabiente.direccion_documento" />
							</div>	      
							<div class="p-field">
								<label for="dias_entrega">Dias de Entrega</label>
								<InputNumber id="dias_entrega" v-model="ctahabiente.dias_entrega" showButtons :min="0"/>
							</div>		
						</div>	
					</TabPanel>
					<TabPanel :disabled="esNuevo">
						<template #header>
							<i class="pi pi-users p-m-1"></i>
							<span>Contactos</span>
						</template>
						<Toolbar class="p-mb-4">
							<template v-slot:right>
								<Button label="Nuevo Contacto" icon="pi pi-plus" class="p-button-success p-mr-2" @click="nuevoContacto" />				
							</template>
						</Toolbar>		
						<DataTable ref="contactos" :value="ctahabiente.contactos" class="p-datatable-sm" dataKey="id_contacto" v-model:selection="contacto" :paginator="true" :rows="5"
								paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPag Link CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[5,10,25]"
								currentPageReportTemplate="Mostrando {first} de {last} de {totalRecords}">
						<Column field="nombre" header="Nombres" headerStyle="width: 30%">
							<template #body="slotProps">
								{{slotProps.data.nombre}} {{slotProps.data.apellido}}
							</template>
						</Column>
						<Column field="celular" header="Celular" headerStyle="width: 30%"></Column>
						<Column field="email" header="email" headerStyle="width: 30%"></Column>
						<Column>
							<template #body="slotProps">
								<Button icon="pi pi-pencil" class="p-button-rounded p-button-success p-mr-1" @click="editContacto(slotProps.data)" />
								<Button icon="pi pi-trash" class="p-button-rounded p-button-warning p-mr-1" @click="confirmDeleteContacto(slotProps.data)" />
							</template>
						</Column>							
						</DataTable>
					</TabPanel>
					<TabPanel :disabled="esNuevo">
						<template #header>
							<i class="pi pi-home p-m-1"></i>
							<span>Direcciones</span>
						</template>
						<Toolbar class="p-mb-4">
							<template v-slot:right>
								<Button label="Nuevo" icon="pi pi-plus" class="p-button-success p-mr-2" @click="nuevaDireccion" />				
							</template>
						</Toolbar>	
						<DataTable ref="direcciones" :value="ctahabiente.direcciones" class="p-datatable-sm" dataKey="id_cta_habiente_direccion" :paginator="true" :rows="5"
								paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPag Link CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[5,10,25]"
								currentPageReportTemplate="Mostrando {first} de {last} de {totalRecords}">
						<Column field="descripcion" header="Descripcion" headerStyle="width: 30%"></Column>
						<Column field="direccion" header="Dirección" headerStyle="width: 30%"></Column>
						<Column>
							<template #body="slotProps">
								<Button icon="pi pi-pencil" class="p-button-rounded p-button-success p-mr-1" @click="editDireccion(slotProps.data)" />
								<Button icon="pi pi-trash" class="p-button-rounded p-button-warning p-mr-1" @click="confirmDeleteDireccion(slotProps.data)" />
							</template>
						</Column>							
						</DataTable>
					</TabPanel>						
					<TabPanel :disabled="esNuevo">
						<template #header>
							<i class="pi pi-calendar p-m-1"></i>
							<span>Condiciones de Pago</span>
						</template>
						<Toolbar class="p-mb-4">
							<template v-slot:right>
								<Button label="Nuevo" icon="pi pi-plus" class="p-button-success p-mr-2" @click="nuevaCondicionPago" />				
							</template>
						</Toolbar>	
						<DataTable ref="condicionesPago" :value="ctahabiente.condiciones_pago" class="p-datatable-sm" dataKey="id_cta_habiente_cond_pago" :paginator="true" :rows="5"
								paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPag Link CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[5,10,25]"
								currentPageReportTemplate="Mostrando {first} de {last} de {totalRecords}">
						<Column field="condicion_pago" header="Condición Pago" headerStyle="width: 30%"></Column>
						<Column field="dias" header="Plazo en días" headerStyle="width: 30%"></Column>
						<Column>
							<template #body="slotProps">
								<Button icon="pi pi-trash" class="p-button-rounded p-button-warning p-mr-1" @click="confirmDeleteCondicionPago(slotProps.data)" />
							</template>
						</Column>							
						</DataTable>
					</TabPanel>						
				</TabView>
				<Button label="Guardar" icon="pi pi-check" class="p-button" @click="saveCtaHabiente" />

				<Dialog v-model:visible="contactoDlg" :style="{width: '450px'}" header="Contacto" :modal="true">
					<div class="p-fluid">
						<div class="p-field">
							<label for="nombre">Nombres</label>
							<InputText v-model="contacto.nombre" />
						</div>
						<div class="p-field">
							<label for="apellido">Apellidos</label>
							<InputText v-model="contacto.apellido" />
						</div>			
						<div class="p-field">
							<label for="email">Email</label>
							<InputText v-model="contacto.email" />
						</div>			
						<div class="p-field">
							<label for="telefono">Teléfono</label>
							<InputText v-model="contacto.telefono" />
						</div>									
						<div class="p-field">
							<label for="extension">Extensión</label>
							<InputText v-model="contacto.extension" />
						</div>								
						<div class="p-field">
							<label for="celular">Celular</label>
							<InputText v-model="contacto.celular" />
						</div>						
						<div class="p-field">
							<label for="celular">Departamento</label>
							<InputText v-model="contacto.departamento" />
						</div>						
						<div class="p-field">
							<label for="celular">Observaciones</label>
							<InputText v-model="contacto.observaciones" />
						</div>						
					</div>
					<template #footer>
						<Button label="No" icon="pi pi-times" class="p-button-text" @click="contactoDlg = false"/>
						<Button label="Sí" icon="pi pi-check" class="p-button-text" @click="saveContacto" />
					</template>
				</Dialog>

				<Dialog v-model:visible="deleteContactoDlg" :style="{width: '450px'}" header="Confirmar" :modal="true">
					<div class="confirmation-content">
						<i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
						<span v-if="ctahabiente">Esta seguro que desea eliminar el contacto seleccionado?<br><b>[{{ contacto.nombre }} {{contacto.apellido}}]</b>?</span>
					</div>
					<template #footer>
						<Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteContactoDlg = false"/>
						<Button label="Sí" icon="pi pi-check" class="p-button-text" @click="deleteCtaHabienteContacto" />
					</template>
				</Dialog>	

				<Dialog v-model:visible="direccionDlg" :style="{width: '450px'}" header="Dirección" :modal="true">
					<div class="p-fluid">
						<div class="p-field">
							<label for="descripcion">Descripción</label>
							<InputText v-model="direccion.descripcion" />
						</div>	
						<div class="p-field">
							<label for="id_pais">Pais</label>
							<Dropdown v-model="direccion.id_pais" :options="lkpPaises" @change="onChangePais()" optionLabel="pais" optionValue="id_pais" placeholder="Seleccionar..." :loading="loadingCiudades"/>
						</div>		
						<div class="p-field">
							<label for="id_estado">Departamento ó Estado</label>
							<Dropdown v-model="direccion.id_estado" :options="lkpEstados" @change="onChangeEstado()" optionLabel="estado" optionValue="id_estado" placeholder="Seleccionar..." :loading="loadingCiudades"/>
						</div>								
						<div class="p-field">
							<label for="id_ciudad">Ciudad</label>
							<Dropdown v-model="direccion.id_ciudad" :options="lkpCiudades" optionLabel="ciudad" optionValue="id_ciudad" placeholder="Seleccionar..." />
						</div>							
						<div class="p-field">
							<label for="direccion">Dirección</label>
							<InputText v-model="direccion.direccion" />
						</div>																	
						<div class="p-field">
							<label for="codigo_postal">Código Postal</label>
							<InputText v-model="direccion.codigo_postal" />
						</div>								
					</div>
					<template #footer>
						<Button label="No" icon="pi pi-times" class="p-button-text" @click="direccionDlg = false"/>
						<Button label="Sí" icon="pi pi-check" class="p-button-text" @click="saveDireccion" />
					</template>
				</Dialog>

				<Dialog v-model:visible="deleteDireccionDlg" :style="{width: '450px'}" header="Confirmar" :modal="true">
					<div class="confirmation-content">
						<i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
						<span v-if="ctahabiente">Esta seguro que desea eliminar la dirección seleccionada?<br><b>[{{ direccion.descripcion }}]</b>?</span>
					</div>
					<template #footer>
						<Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteDireccionDlg = false"/>
						<Button label="Sí" icon="pi pi-check" class="p-button-text" @click="deleteCtaHabienteDireccion" />
					</template>
				</Dialog>	

				
				<Dialog v-model:visible="condicionPagoDlg" :style="{width: '450px'}" header="Nueva Condición de Pago" :modal="true">
					<div class="p-fluid">
						<div class="p-field">
							<label for="id_condicion_pago">Condición de Pago</label>
							<Dropdown v-model="condicionPago.id_condicion_pago" :options="lkpCondicionesPago" optionLabel="condicion_pago" optionValue="id_condicion_pago" placeholder="Seleccionar..."/>
						</div>		
					</div>
					<template #footer>
						<Button label="No" icon="pi pi-times" class="p-button-text" @click="condicionPagoDlg = false"/>
						<Button label="Sí" icon="pi pi-check" class="p-button-text" @click="saveCondicionPago" />
					</template>
				</Dialog>

				<Dialog v-model:visible="deleteCondicionPagoDlg" :style="{width: '450px'}" header="Confirmar" :modal="true">
					<div class="confirmation-content">
						<i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
						<span v-if="ctahabiente">Esta seguro que desea eliminar la condición de pago seleccionada?<br><b>[{{ condicionPago.condicion_pago }}]</b>?</span>
					</div>
					<template #footer>
						<Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteCondicionPagoDlg = false"/>
						<Button label="Sí" icon="pi pi-check" class="p-button-text" @click="deleteCtaHabienteCondicionPago" />
					</template>
				</Dialog>	

				<OverlayPanel ref="op">
					<div class="p-fluid">
						<div class="p-field">
						<label for="Periodo">Período</label>
						<SeleccionFecha @nuevoPeriodo="actualizarPeriodo"/>
						</div>
						<div class="p-field">
							<label for="desde">Desde</label>
							<Calendar id="desde" v-model="desde" :key="periodoUpdate"/>
							</div>		
						<div class="p-field">
							<label for="hasta">Hasta</label>
							<Calendar id="hasta" v-model="hasta" :key="periodoUpdate"/>
						</div>	
						<div class="p-field">
							<label for="id_moneda">Moneda</label>
							<Dropdown v-model="selIdMonedaEstadoCuenta" :options="lkpMonedas" optionLabel="moneda" optionValue="id_moneda" placeholder="Seleccionar..." />
						</div>	
						<div class="p-field">
							<Button label="Descargar" icon="pi pi-download" iconPos="right" @click="descargarEstadoCuenta"/>
						</div>				
					</div>								
				</OverlayPanel>					
			</div>
		</div>
	</div>

</template>

<script>
// Import component
import SeleccionFecha from '../components/SeleccionFecha.vue';
import Loading from 'vue-loading-overlay';
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';
import GrlService from '../service/grl.service';

export default {
	data() {
		return {
			id_cta_habiente_info: this.id,
			lkpCategorias: this.$store.state.auth.currentAppCtx.ctahabiente_categorias,
			lkpMonedas: this.$store.state.auth.currentAppCtx.Monedas,
			lkpRegiones: this.$store.state.auth.currentAppCtx.ctahabiente_regiones,
			lkpCtaHabienteTipos: this.$store.state.auth.currentAppCtx.ctahabiente_tipos,
			lkpPreciosLista: this.$store.state.auth.currentAppCtx.precio_listas,
			lkpProductosLista: this.$store.state.auth.currentAppCtx.producto_listas,
			lkpCondicionesPago: this.$store.state.auth.currentAppCtx.CondicionesPago,
			lkpPaises: this.$store.state.auth.currentAppCtx.Paises,
			lkpEstados: [],
			lkpCiudades: [],
			lkpDireccionesTipo: this.$store.state.auth.currentAppCtx.direccion_tipo,
			selectedCtaHabientes: null,
			filters: {},
			loading: false,
			loadingCiudades: false,
			fullPageLoading: false,
			submitted: false,
			ctahabiente: {
				id_cta_habiente : "", 
				nombre_comercial: "", 
				razon_social: "",
				nit: "", 
				website: "", 
				telefono: "", 
				codigo: "", 
				id_retencion_tipo: "", 
				email: "", 
				id_cta_habiente_info: "",
				id_empresa: this.$store.state.auth.currentAppCtx.id_referencia,
				id_cta_habiente_tipo: "CLIEN", 
				id_cta_habiente_categoria: "", 
				id_moneda: this.$store.state.auth.currentAppCtx.Monedas[0].id_moneda,
				id_region: "", 
				id_precio_lista: "", 
				pct_descuento: 0.0, 
				nombre_documento: "", 
				direccion_documento: "",
				dias_entrega: 0, 
				dias_credito: 0, 
				nit_documento: "", 
				exento: "N", 
				limite_credito: 0.0, 
				id_producto_lista: "", 
				activo: true,
				contactos: [],
				direcciones: [],
				condiciones_pago: []
			},
			desde: new Date(),
			hasta: new Date(),
			selIdMonedaEstadoCuenta: this.$store.state.auth.currentAppCtx.monedaBase.id_moneda,
			esNuevo: true,
			contactoDlg: false,
			deleteContactoDlg: false,
			contacto: null,
			direccionDlg: false,
			deleteDireccionDlg: false,
			direccion: null,
			condicionPago: null,
			condicionPagoDlg: false,
			deleteCondicionPagoDlg: false
		}
	},
	components: {
		SeleccionFecha, Loading
	},	
	mounted() {
	},
	props: {
		id: {
			type: String,
			required: true,
			default: ''
			}			
	},		
	created(){
		if(this.id != '')
		{
			this.fetchData();
		} else {
			this.openNew();
		}
	},
	methods: {
		actualizarPeriodo(periodo)
		{
			this.desde = periodo.desde;
			this.hasta = periodo.hasta;
			this.periodoUpdate += 1;
		},
		getEstadoCuenta(event) {
			this.$refs.op.toggle(event);
		},
		descargarEstadoCuenta()
		{
			this.loading = true;

			let filtro = {
				reporte: 'cxcEstadoCuenta',
				titulo: 'Estado de Cuenta',						
				params: [
					{ParamName: 'idEmpresa', Valor: this.ctahabiente.id_empresa},
					{ParamName: 'idMoneda', Valor: this.selIdMonedaEstadoCuenta},
					{ParamName: 'idCtaHabienteInfo', Valor: this.ctahabiente.id_cta_habiente_info},
					{ParamName: 'FechaIni', Valor: this.desde},
					{ParamName: 'FechaFin', Valor: this.hasta}
				]
			};

			GrlService.getEstadoCuentaPDF(this.$store.state.auth.currentAppCtx, filtro, false).then(
				response => {
					//Create a Blob from the PDF Stream
					this.loading = false;
					const file = new Blob(
					[response.data], 
					{type: 'application/pdf'});
					//Build a URL from the file
					const fileURL = URL.createObjectURL(file);	

					var link = document.createElement('a');
					link.href = fileURL;
					link.download = `EstadoCuenta(${this.ctahabiente.nombre_comercial}).pdf`;
					link.dispatchEvent(new MouseEvent('click'));
				}				
			).catch(
				data => {
					this.anularDialog = false;
					this.mov = {};
					this.$toast.add({severity:'error', summary: 'Error', detail: data})
				}
			)					
		},
		formatCurrency(value) {
			if(value)
				return value.toLocaleString('en-US', {style: 'currency', currency: 'GTQ'});
			return;
		},
		openNew() {
			this.ctahabiente = {
				id_cta_habiente : "", 
				nombre_comercial: "", 
				razon_social: "",
				nit: "", 
				website: "", 
				telefono: "", 
				codigo: "", 
				id_retencion_tipo: "", 
				email: "", 
				id_cta_habiente_info: "",
				id_empresa: this.$store.state.auth.currentAppCtx.id_referencia,
				id_cta_habiente_tipo: "CLIEN", 
				id_cta_habiente_categoria: "", 
				id_moneda: this.lkpMonedas[0].id_moneda,
				id_region: "", 
				id_precio_lista: "", 
				pct_descuento: 0.0, 
				nombre_documento: "", 
				direccion_documento: "",
				dias_entrega: 0, 
				dias_credito: 0, 
				nit_documento: "", 
				exento: "N", 
				limite_credito: 0.0, 
				id_producto_lista: "", 
				activo: 'S',
				activoDisplay: true,
				contactos: [],
				direcciones: [],
				condiciones_pago: []						
			};
			this.esNuevo = true;
			this.submitted = false;
			this.ctaHabienteDialog = true;
		},		
		saveCtaHabiente() {						
			this.ctahabiente.activo = this.ctahabiente.activoDisplay ? 'S': 'N';
			this.loading = true;
			GrlService.registrarCtaHabiente(this.$store.state.auth.currentAppCtx, this.ctahabiente).then(data => {
				this.loading = false;
				this.ctahabiente.id_cta_habiente_info = data.id_cta_habiente_info;
				this.ctahabiente.id_cta_habiente = data.id_cta_habiente;
				this.esNuevo = false;
				this.$toast.add({severity:'success', summary: 'Exito', detail: 'Los cambios han sido guardados con éxito!', life: 3000});									
			}).catch(
				data => {
					this.loading = false;
					this.$toast.add({severity:'error', summary: 'Error', detail: data})
				}
			);			
		},

		nuevoContacto()
		{
			this.contacto = {
				id_contacto: "",
				id_cta_habiente_info: this.ctahabiente.id_cta_habiente_info,
				nombre: "",
				apellido: "",
				email: "",
				telefono: "",
				celular: "",
				extension: "",
				departamento: "",
				observaciones: "",
				login: "",
				contrasena: ""				
			}
			this.contactoDlg = true;
		},

		editContacto(CtahabienteContacto) {
			this.contacto = CtahabienteContacto;
			this.contactoDlg = true;
		},

		confirmDeleteContacto(CtahabienteContacto) {
			this.contacto = CtahabienteContacto;
			this.deleteContactoDlg = true;
		},

		deleteCtaHabienteContacto()
		{
			this.loading = true;
			GrlService.eliminarCtaHabienteContacto(this.$store.state.auth.currentAppCtx, this.contacto).then(()=>{
				this.loading = false;
				this.ctahabiente.contactos = this.ctahabiente.contactos.filter(val => val.id_contacto !== this.contacto.id_contacto);
				this.contacto = {};
				this.deleteContactoDlg = false;
			}).catch(
				data => {
					this.loading = false;
					this.deleteContactoDlg = false;
					this.$toast.add({severity:'error', summary: 'Error', detail: data})
				}
			);		
		},

		saveContacto() {						
			this.loading = true;
			let esNuevo = (this.contacto.id_contacto == '');
			GrlService.registrarCtaHabienteContacto(this.$store.state.auth.currentAppCtx, this.contacto).then(data =>{
				this.loading = false;

				if(esNuevo){
					this.contacto.id_contacto = data.id_contacto;
					this.ctahabiente.contactos.push(this.contacto);
				}
				this.contactoDlg = false;
			}).catch(
				data => {
					this.loading = false;
					this.contactoDlg = false;
					this.$toast.add({severity:'error', summary: 'Error', detail: data})
				}
			);			
		},	
			
		nuevaDireccion()
		{
			this.direccion = {
				id_cta_habiente_direccion: "",
				id_cta_habiente: this.ctahabiente.id_cta_habiente,
				id_estado: "",
				id_ciudad: "",
				descripcion: "",
				id_direccion_tipo: "",
				id_pais: this.$store.state.auth.currentAppCtx.Pais.id_pais,
				codigo_postal: "",
			}
			this.direccionDlg = true;
		},

		editDireccion(CtahabienteDireccion) {
			this.direccion = CtahabienteDireccion;
			this.direccionDlg = true;
			this.onChangePais();
		},
		confirmDeleteDireccion(CtahabienteDireccion) {
			this.direccion = CtahabienteDireccion;
			this.deleteDireccionDlg = true;
		},

		deleteCtaHabienteDireccion()
		{
			this.loading = true;
			GrlService.eliminarCtaHabienteDireccion(this.$store.state.auth.currentAppCtx, this.direccion).then(()=>{
				this.loading = false;
				this.ctahabiente.direcciones = this.ctahabiente.direcciones.filter(val => val.id_cta_habiente_direccion !== this.direccion.id_cta_habiente_direccion);
				this.direccion = {};
				this.deleteDireccionDlg = false;
			}).catch(
				data => {
					this.loading = false;
					this.deleteDireccionDlg = false;
					this.$toast.add({severity:'error', summary: 'Error', detail: data})
				}
			);		
		},	

		saveDireccion() {
			this.loading = true;
			let esNuevo = (this.direccion.id_cta_habiente_direccion == '');
			GrlService.registrarCtaHabienteDireccion(this.$store.state.auth.currentAppCtx, this.direccion).then(data =>{
				this.loading = false;

				if(esNuevo){
					this.direccion.id_cta_habiente_direccion = data.id_cta_habiente_direccion;
					this.ctahabiente.direcciones.push(this.direccion);
				}
				this.direccionDlg = false;
			}).catch(
				data => {
					this.loading = false;
					this.direccionDlg = false;
					this.$toast.add({severity:'error', summary: 'Error', detail: data})
				}
			);			
		},		

		nuevaCondicionPago()
		{
			this.condicionPago = {
				id_cta_habiente_cond_pago: "",
				id_cta_habiente_info: this.ctahabiente.id_cta_habiente_info,
				id_condicion_pago: "",
				condicion_pago: "",
				dias: 0,
				pct_descuento: 0
			}
			this.condicionPagoDlg = true;
		},

		async saveCondicionPago() {
			this.loading = true;
			this.condicionPagoDlg = false;
			try {
				var data = await GrlService.registrarCtaHabienteCondicionPago(this.$store.state.auth.currentAppCtx, this.condicionPago);
				this.condicionPago.id_cta_habiente_cond_pago = data.id_cta_habiente_cond_pago;
				var condicion = this.lkpCondicionesPago.find((e)=> e.id_condicion_pago == this.condicionPago.id_condicion_pago);

				this.condicionPago.condicion_pago = condicion.condicion_pago;
				this.condicionPago.dias = condicion.dias;

				this.ctahabiente.condiciones_pago.push(this.condicionPago);

			} catch (err) {				
				this.$toast.add({severity:'error', summary: 'Error', detail: data})
			} finally {
				this.loading = false;
			}	
		},		

		confirmDeleteCondicionPago(CondicionPago) {
			this.condicionPago = CondicionPago;
			this.deleteCondicionPagoDlg = true;
		},

		
		async deleteCtaHabienteCondicionPago()
		{
			this.loading = true;
			try {
				var data = await GrlService.eliminarCtaHabienteCondicionPago(this.$store.state.auth.currentAppCtx, this.condicionPago);
				this.ctahabiente.condiciones_pago = this.ctahabiente.condiciones_pago.filter(val => val.id_cta_habiente_cond_pago !== this.condicionPago.id_cta_habiente_cond_pago);
				this.condicionPago = {};
				this.deleteCondicionPagoDlg = false;
			} catch (err) {
				this.$toast.add({severity:'error', summary: 'Error', detail: err})
			} finally {
				this.loading = false;
			}	
		},	

		onChangePais() {
			this.loadingCiudades = true;
			GrlService.getEstados(this.$store.state.auth.currentAppCtx, this.direccion.id_pais).then(data => {				
				this.loadingCiudades = false;
				this.lkpEstados = data;

				if(this.lkpEstados.length > 0){
					if(this.direccion.id_estado != ''){
						this.onChangeEstado();	
					}					
				} else {
					this.direccion.id_estado = '';
					this.lkpEstados = [];
					this.lkpCiudades = [];
				}	
			}
			).catch(
				data => {
					this.loadingCiudades = false;
					this.$toast.add({severity:'error', summary: 'Error', detail: data, life: 3000})
				}
			)
		},		
		onChangeEstado() {
			this.loadingCiudades = true;
			GrlService.getCiudades(this.$store.state.auth.currentAppCtx, this.direccion.id_estado).then(data => {				
				this.loadingCiudades = false;
				this.lkpCiudades = data;

				if(this.lkpCiudades.length == 0){
					this.direccion.id_ciudad = '';
					this.lkpCiudades = [];
				}	
			}
			).catch(
				data => {
					this.loadingCiudades = false;
					this.$toast.add({severity:'error', summary: 'Error', detail: data, life: 3000})
				}
			)
		},					
		async fetchData () {
			this.loading = true;
			try {
				this.error = this.post = null;
				this.id_cta_habiente_info = this.id;
				var data = await GrlService.getCtaHabiente(this.$store.state.auth.currentAppCtx, this.id_cta_habiente_info);				
				this.ctahabiente = data;
				this.ctahabiente.activoDisplay = (data.activo == 'S');
				this.esNuevo = false;				
			} catch(err) {
				this.$toast.add({severity:'error', summary: 'Error', detail: err, life: 3000})
			} finally {
				this.loading = false;
			}			
		}		
	}
}
</script>

<style scoped lang="scss">
.table-header {
	display: flex;
	justify-content: space-between;
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}
/* Responsive */
.p-datatable-customers .p-datatable-tbody > tr > td .p-column-title {
	display: none;
}

@media screen and (max-width: 960px) {
	::v-deep(.p-datatable) {
		&.p-datatable-customers {
			.p-datatable-thead > tr > th,
			.p-datatable-tfoot > tr > td {
				display: none !important;
			}

			.p-datatable-tbody > tr {
				border-bottom: 1px solid var(--surface-d);

				> td {
					text-align: left;
					display: block;
					border: 0 none !important;
					width: 100% !important;
					float: left;
					clear: left;
					border: 0 none;

					&:last-child {
						text-align: center;
					}

					.p-column-title {
						padding: .4rem;
						min-width: 30%;
						display: inline-block;
						margin: -.4rem 1rem -.4rem -.4rem;
						font-weight: bold;
					}

					.p-progressbar {
						margin-top: .5rem;
						display: inline-block;
						width: 60%;
					}

					.p-rating {
						display: inline-block;
					}
				}
			}

			.p-datatable-tbody > tr.p-rowgroup-footer{
				display: flex;
			}
		}
	}
}

::v-deep(.p-toolbar) {
	flex-wrap: wrap;
	.p-button {
		margin-bottom: .25rem;
	}
}
</style>
